import propTypes from 'prop-types';
import React from 'react';


const TagTitle = ({ title, shortDescription, longDescription, metaTitle }) => {
  return (
    <div className="bg-white md:w-4/6 pt-6 pb-12 rounded-md">
      <h1 className="text-black text-3xl md:text-4xl sm:text-5xl md:text-5xl font-bold">
        <span className="font-lg">{title}</span>
      </h1>
      <h3 className="text-gray-800 text-lg font-normal mb-6 italic">{shortDescription}</h3>
      <p className="text-black text-md font-bold">{metaTitle}</p>
      <p className="text-gray-800 text-md font-semibold mb-0">{longDescription}</p>
    </div>
  );
};

TagTitle.propTypes = {
  title: propTypes.string.isRequired,
  metaTitle: propTypes.string.isRequired,
  shortDescription: propTypes.string.isRequired,
  longDescription: propTypes.string.isRequired,
};

export default TagTitle;
